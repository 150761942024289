.dropdown {
    height: 2rem !important;
    color: #15284B !important;
    outline: none !important;
    border: 1px solid #D4D5D8 !important;
    border-radius: 0.25rem;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: 0rem;
    width: 10rem;
  }

  .dropdown-option {
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: 0rem;
    color: #15284B;
    width: 10rem;
  }