.viewing-dropdown {
  width: 19.6rem;
  height: 2rem;
  border: 1px solid #D4D5D8;
  border-radius: 4px;
}

.viewing-dropdown-menu-options {
  padding: 8px 8px 8px 0px;
  width: 100%;
  max-height: 14rem;
  overflow: auto;
}

.viewing-dropdown .viewing-dropdown-values {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 8px;
  gap: 8px;
  cursor: pointer;
}

.viewing-dropdown .viewing-dropdown-text {
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  white-space: nowrap;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: 0.4px !important;
  color: #15284B !important;
}

.viewing-dropdown .down-caret-logo {
  width: 2rem;
}

.viewing-dropdown-menu {
  position: absolute;
  width: 19.6rem;
  border: 1px solid #D4D5D8;
  background-color: #ffffff;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 3px;
  /* padding: 8px 8px 8px 8px; */
  top: 10.3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 100000;
  /* left: 1rem; */
}

.values-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px 4px 0px;
}

.values-container:hover {
  background-color: #E8F0FB;
}

.viewing-dropdown-menu-options .values {
  height: 2rem;
  width: 100%;
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 8px 8px 0px;
  cursor: context-menu;
}

.viewing-dropdown-menu-options .extraction-logo {
  padding: 8px 8px 8px 0px;
  cursor: pointer;
}

.viewing-dropdown-menu-view-comp-button {
  padding: 8px 8px 8px 8px;
}