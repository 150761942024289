.chatbot-container {
  width: 307px;
  height: 390px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chatbot-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 16px;
  height: 40px;
  background: #395DAB;
  color: white;
  width: 100%;
}