.status-container {
  color: #15284B;
  display: flex;
  align-items: center;
}

.dots {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  margin-right: 5px;
}

.loading {
  margin-right: 0.25rem;
  margin-top: 0.25rem;
}