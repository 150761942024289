.pdf-editor-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
}

.pdf-editor-header {
  background-color: #2D4081;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.pdf-editor-content {
  /* flex-grow: 1; */
  overflow-y: hidden;
}

.classification-chip {
  line-height: 15px;
  padding: 2px 4px 2px 4px;
  text-align: center;
  color: #0065FF;
  background: #e5edf0;
  font-weight: 500;
}

.classification-dropdown {
  height: 2rem !important;
  color: #15284B !important;
  outline: none !important;
  border: 1px solid #D4D5D8 !important;
  border-radius: 0.25rem;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 0rem;
}

.classification-dropdown-option {
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 0rem;
  color: #15284B;
  width: 10rem;
  font-size: 14px;
  max-height: 20px;
  overflow-y: auto;
}

.footer {
  background: #2D4081;
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
}
.align-center {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.new-document {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 4px;
  gap: 8px;
  width: 215px;
  height: 60px;
  background: #F1F3F4;
  border: 1px solid #D4D5D8;
}