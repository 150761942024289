.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 64px;
  padding: 0px 0px 6px 0px;
  z-index: 100000;
  transition: all 0.5s ease;
}

.sidebar ul {
  background-image: linear-gradient(180deg, #26a685, #233f80);
  padding: 4px 0px;
}

.sidebar.open {
  width: 270px;
}

.sidebar .logo-details {
  height: 60px;
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  position: relative;
}

.sidebar .logo-details .icon {
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar .logo-details .logo_name {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name {
  opacity: 1;
}

.sidebar .icons {
  color: #26a685;
  height: 20px;
  min-width: 40px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}

.sidebar .menu-icon {
  color: #fff;
  font-weight: bold;
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}

.sidebar .nav-list {
  height: 100%;
}

.sidebar a,
.sidebar li {
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  cursor: pointer;
  position: relative;
  list-style: none;
  padding: 8px 0px;
  border-bottom: 1px solid rgba(158, 158, 158, 0.4);
}

.sidebar .bottom-nav {
  position: fixed;
  bottom: 1rem;
  width: 64px;
  border-bottom: none;
}

.sidebar a .tooltip,
.sidebar li .tooltip {
  position: absolute;
  top: -20px;
  left: calc(100% + 5px);
  z-index: 3;
  background-color: #000;
  color: #fff;
  padding: 6px 12px;
  border-radius: 5%;
  font-size: 0.8rem;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: 0s;
}

.sidebar a:hover .tooltip,
.sidebar li:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  transform: translateY(-50%);
}

.sidebar.open a .tooltip {
  display: none;
}

.sidebar input {
  font-size: 15px;
  color: #fff;
  font-weight: 400;
  outline: none;
  height: 50px;
  width: 100%;
  width: 50px;
  border: none;
  border-radius: 12px;
  transition: all 0.5s ease;
  background: #1d1b31;
}

.sidebar.open input {
  padding: 0 20px 0 50px;
  width: 100%;
}

.sidebar a .links_name,
.sidebar li .links_name {
  color: #fff;
  margin-left: 10px;
  font-size: 18px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}

.sidebar.open a .links_name {
  opacity: 1;
  pointer-events: auto;
}

.sidebar a .icons,
.sidebar li .icons {
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  /* border-radius: 50%; */
  margin-left: 16px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #fff; */
}

.sidebar a.active .icons,
.sidebar li.active .icons {
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  /* border-radius: 50%; */
  margin-left: 16px;
  margin-bottom: 8px;
  border: 1px solid #24b082;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar a .menu-icon,
.sidebar li .menu-icon {
  height: 35px;
  line-height: 32px;
  font-size: 18px;
  margin-left: 10px;
  margin-bottom: 5px;
  background-color: transparent;
}

.sidebar a:hover,
.sidebar li:hover {
  opacity: 1;
}

.sidebar a.active,
.sidebar li.active {
  /* border-left: 6px solid #ffffff7a; */
  /* opacity: 1; */
}

.sidebar a.active>span.icons,
.sidebar li.active>span.icons {
  /* margin-left: 9px; */
}

@media (max-width: 420px) {

  .sidebar a .tooltip,
  .sidebar li .tooltip {
    display: none;
  }
}

.user-detail-font {
  display: block;
}

.popup-user-detail {
  position: fixed;
  bottom: 1rem;
  border: 2px solid #f1f1f1;
  z-index: 9;
  left: 5rem;
  width: 12rem;
  background-color: white !important;
  border-radius: 5px;
  box-shadow: 6px 9px 5px rgb(9 30 66 / 20%), 0px 0px 1px rgb(9 30 66 / 31%);
  padding: 0.5rem !important;
  /* opacity: 1 !important; */
}

.new-menu-style {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.new-menu-style-open {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  padding-left: 8px !important;
}