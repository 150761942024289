:root {
  --baseFontSize: 10px;
  --baseLineHeight: 10px;
  --bpsmall: 768px;
  --bpmedium: 1024px;
  --bplarge: 1366px;
  --bpxlarge: 1920px;
  --bpxxlarge: 2560px;
  background-color: white;
}

html {
  overflow-x: hidden;
  font-size: var(--baseFontSize);
  background-color: #eeedf2;
  scrollbar-width: thin;
  scrollbar-color: #c2c1c1 #dfdbdb;
  background-color: #fff;
}
@media screen and (min-width: 600px) {
  html {
    font-size: calc(var(--baseFontSize) * 1);
    line-height: calc(var(--baseLineHeight) * 1.5);
  }
}
@media screen and (min-width: 768px) {
  html {
    font-size: calc(var(--baseFontSize) * 1.2);
    line-height: calc(var(--baseLineHeight) * 1.8);
  }
}
@media screen and (min-width: 900px) {
  html {
    font-size: calc(var(--baseFontSize) * 1.4);
    line-height: calc(var(--baseLineHeight) * 2.1);
  }
}
@media screen and (min-width: 1366px) {
  html {
    font-size: calc(var(--baseFontSize) * 1.6);
    line-height: calc(var(--baseLineHeight) * 2.4);
  }
}
@media screen and (min-width: 1920px) {
  html {
    font-size: calc(var(--baseFontSize) * 1.8);
    line-height: calc(var(--baseLineHeight) * 2.7);
  }
}
@media screen and (min-width: 2560px) {
  html {
    font-size: calc(var(--baseFontSize) * 2);
    line-height: calc(var(--baseLineHeight) * 3);
  }
}

body {
  margin: 0 !important;
  background-color: white;
  height: 100%;
}

.titles {
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
}
/* Bold Fonts */
.h1Medium {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
}

.h2Medium {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
}

.h3Medium {
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 500;
}

.h4Medium {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
}

.h5Medium {
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 0.875rem;
}

/* Regular Fonts */
.h1Regular {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 400;
}

.h2Regular {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
}

.h3Regular {
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 400;
}

.h4Regular {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
}

.h5Regular {
  font-size: 0.625rem;
  line-height: 0.875rem;
  font-weight: 400;
}