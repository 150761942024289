.doc-header {
  display: flex;
  flex: 2;
  background: white;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  margin-right: 1.5rem;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
}

.header-label-flex {
  display: flex;
  align-items: center;
}

.doc-header span.heading-content {
  padding-left: 0.5rem;
  max-width: 400px !important;
}

.doc-header span.heading-content>a,
.doc-header span.heading-content {
  color: #395DAB;
  text-decoration: none;
}

.doc-header div.status {
  text-align: end;
}

.bg-custom {
  color: #fff !important;
  font-weight: 400 !important;
  padding: 8px !important;
  font-size: 0.8rem !important;
  border-radius: 10% !important;
}

.nav-btn {
  padding: 0.25rem 0.5rem !important;
  border-radius: 35% !important;
}

.dropdown-navigation {
  height: 2rem !important;
  color: #15284B !important;
  outline: none !important;
  border: 1px solid #D4D5D8 !important;
  border-radius: 0.25rem;
  margin-left: 0.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 0rem;
  width: 15rem;
}

.navigation-dropdown-option {
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 0rem;
  color: #15284B;
  width: 15rem;
}

#docName.truncateDocName {
  max-width: 20rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

.review-time {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2px;
  gap: 8px;
  background: #E4E6EF;
  border-radius: 1px;
  color: #3C4C68;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
}