#tab-flow-outlet {
  width: 100%;
}

.tabs-container {
  width: 98.75%;
  margin-left: 0.5rem;
  margin-top: 0.25rem;
}

.primary-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 0.5rem;
  margin-left: 0.5rem;
  margin-top: 0.25rem;

  width: 98.75%;
  height: 48px;
  background: #FFFFFF;
}

.truncate-primary-header-description {
  max-width: 20rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}