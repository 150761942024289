.btn {
    border-radius: 0.25rem !important;
}

.label-truncate {
    /* max-width: 160px !important; */
}

.disabled-cursor {
    pointer-events: none;
}

.attachment-table {
    overflow: auto !important;
    max-height: 80vh !important;
}

.attachment-table thead th {
    background-color: #f2f2f2 !important;
    position: sticky !important;
    z-index: 15 !important;
    top: 0 !important;
}

.attachment-table>table>tbody>tr>td {
    padding: 0.8rem !important;
}

.extra-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 56px;
    padding: 0px;
    gap: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 8px;
    padding-right: 8px;
    width: 98.5%;
    background-color: #E4E6EF;
}

.button-disabled {
    cursor: not-allowed !important;
}

.download-all-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.download-text {
    border: 1px solid #E4E6EF;
    padding: 5px;
    background-color: #E4E6EF;
    margin-bottom: 0.5rem;
    cursor: pointer;
}
