.popup-style {
  background: #FFFFFF;
  box-shadow: 0px 8px 12px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 3px;
  padding: 8px 16px;
  gap: 16px;
  width: 18.563rem;
}

.popup-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #15284B;
  margin: 0;
}

.popup-warning-text {
  margin: 0;
  color: #3C4C68;
}

.timer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px 4px 12px;
  gap: 10px;
  background: #E4E6EF;
  border-radius: 4px 0px 0px 4px;
}

.digits {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #15284B;
}

.timer-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirm-popup-header {
  font-weight: 500;
  font-size: 14px;
  color: #15284B;
  margin: 0;
}

.confirm-popup-content {
  color: #3C4C68;
  margin: 0;
}

.p-confirm-popup .p-confirm-popup-content {
  padding: 0;
  padding-top: 8px;
  padding-right: 16px;
}

.p-confirm-popup .p-confirm-popup-footer {
  padding: 0;
  padding-bottom: 8px;
  padding-right: 16px;
  padding-top: 16px;
}

.timer-actions {
  cursor: pointer;
}

.timer-actions:disabled {
  opacity: 0.4;
}

.review-time {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2px;
  gap: 8px;
  background: #E4E6EF;
  border-radius: 1px;
  color: #3C4C68;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
}
