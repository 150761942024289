.renewal {
    color: #24B082 !important;
    background-color: #D4F1E8 !important;
  }
  
  .invalid {
    color: #DE350B !important;
    background-color: #F6E0DB !important;
  }
  
  .new-business {
    color: #395DAB !important;
    background-color: #E8F0FB !important;
  }
  
  .automated-renewal {
    color: #6554C0 !important;
    background-color: #E5E2FC !important;
  }
  
  .renewal-across-different-carrier {
    color: #D6792D !important;
    background-color: #FDF5E5 !important;
  }
  
