.label-truncate {
    display: flex;
    align-items: center;
    /* max-width: 160px !important; */
}

.status-img {
    width: 15px;
    height: 15px;
    vertical-align: initial;
}
