.label-truncate {
    display: flex;
    align-items: center;
    /* max-width: 160px !important; */
}

.checkbox-td {
    padding-left: 0 !important;
}

.thick-border {
    border-bottom: 1.4px solid rgba(224, 224, 224, 1);
}